import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import Dashboard from './components/Dashboard';
import Login from './components/Login';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/dashboard/:tab',
    element: <Dashboard />,
  },
]);

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AuthContextProvider>
          <RouterProvider router={router} />
        </AuthContextProvider>
      </header>
    </div>
  );
}