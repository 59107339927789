import React from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    border-right: 1px solid rgba(59,59,59,.8);
    border-top: 1px solid rgba(59,59,59,.8);
`
const Menu = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
`
const List = styled.div`
    padding: 10px 40px;
    cursor: pointer;
    &:hover {
        background-color: #2d2d2d;
    }
`
const Item = styled.span`
    color: white;
    font-size: 24px;
    user-select: none;
`

export default function Sidebar() {
    const { tab } = useParams();
    return (
        <Container>
            <Menu>
                <Link to="/dashboard/busstop" style={{ textDecoration: 'none' }}>
                    <List style={tab === "busstop" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>Busstop</Item>
                    </List>
                </Link>
                <Link to="/dashboard/concerthall" style={{ textDecoration: 'none' }}>
                    <List style={tab === "concerthall" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>Concert Hall</Item>
                    </List>
                </Link>
                <Link to="/dashboard/rotatingbillboards" style={{ textDecoration: 'none' }}>
                    <List style={tab === "rotatingbillboards" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>Rotating Billboards</Item>
                    </List>
                </Link>
                <Link to="/dashboard/stadium" style={{ textDecoration: 'none' }}>
                    <List style={tab === "stadium" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>Stadium</Item>
                    </List>
                </Link>
                <Link to="/dashboard/bellyache" style={{ textDecoration: 'none' }}>
                    <List style={tab === "bellyache" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>Bellyache</Item>
                    </List>
                </Link>
                <Link to="/dashboard/webglversion" style={{ textDecoration: 'none' }}>
                    <List style={tab === "webglversion" ? { backgroundColor: '#2d2d2d' } : {}}>
                        <Item>New WebGL Version</Item>
                    </List>
                </Link>
            </Menu>
        </Container>
    )
}