import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";

const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Header = styled.div`
  color: white;
  font-size: 32px;
  font-weight: 600;
`;
const Text = styled.div`
  color: white;
  font-size: 20px;
`;
const Button = styled.button`
  padding: 5px 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #aaaaaa;
  color: #000;
  font-size: 24px;
  border-radius: 5px;
  border: none;
  utline: none;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: #7f7575;
  }
`;

export default function NewWebglVersion() {
  const [version, setVersion] = useState();

  useEffect( () => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/version/get`)
    .then((res) => {
      setVersion(res.data.data.current_version);
    });
  }, []);

  const notify = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/version/update`, {"version": version})
    .then((res) => {
      setVersion(res.data.data.current_version);
    })
  }
  // console.log(version)

  return (
    <Container>
      <Header>Notify Users of a New WebGL Version</Header>
      <Text>
        This will give a 5 minute warning that a new WebGL Version is coming
      </Text>
      <Button onClick={notify}>Notify</Button>
    </Container>
  );
}
