import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { styled } from "styled-components";
import Post from "./item/Post";

const Container = styled.div`
    padding: 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 50px;
`
const UploadButton = styled.div`
    width: calc((100vw - 500px) / 3);
    height: calc((100vw - 500px) / 3 * 0.6);
    background-color: #111519;
    border: 2px solid #32383e;
    outline: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`
const UploadLabel = styled.label`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SaveButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-color: #111519;
    right: 101%;
    transition: right 0.5s ease-in-out;
`
const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SaveButton = styled.button`
    padding: 7px 15px;
    background-color: #1fb8cd;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
`
const Text = styled.p`
    color: white;
    font-size: 16px;
`

export default function RotatingBillboards() {
    const { tab } = useParams();
    const [selectedFile, setSelectedFile] = useState();
    const [images, setImages] = useState([]);
    const [changed, setChanged] = useState("");
    const [saved, setSaved] = useState(false);

    const changeHandler = (event, image) => {
        setSelectedFile(event.target?.files[0]);
        if(event.target?.files[0]) {
            setChanged(image._id);
        }
    };

    const changePost = (image) => {
        const directory = tab;
        const _id = image._id;
        const formData = new FormData();
        console.log("<<<<<<<<<", _id);

        formData.append('file', selectedFile);
        formData.append('directory', directory);
        formData.append('_id', _id);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/user/change`,
            data : formData
        };

        axios(config)
            .then(function (response) {
                setImages(response.data.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        setChanged("");
    }

    const uploadHandler = (event) => {
        setSelectedFile(event.target?.files[0]);
        if(event.target?.files[0]) {
            setSaved(true);
        }
    };

    const savePost = () => {
        const directory = tab;
        const formData = new FormData();

        console.log('---------------------------------');
        console.log(selectedFile);
        formData.append('file', selectedFile);
        formData.append('directory', directory);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/user/upload`,
            data : formData
        };

        axios(config)
            .then(function (response) {
                setImages(response.data.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        setSaved(false);
    }

    const deletePost = (image) => {
        const data = image;
        console.log(image);
        axios.post(`${process.env.REACT_APP_SERVER_URL}/user/delete`, data)
            .then(function (response) {
                setImages(response.data.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/user/posts/rotatingbillboards`)
            .then((res) => {
                setImages(res.data.data.data);
            });
    }, [])

    return (
        <Container>
            {images.map((image, index) => (
                <Post key={index} image={image} changeHandler={(event) => changeHandler(event, image)} changePost={() => changePost(image)} changed={changed} deletePost={() => deletePost(image)} />
            ))}
            <div>
                <Text>
                    File Format: JPG
                    <br />
                    Max Resolution Size: 2000 * 2000
                    <br />
                    Aspect Ratio: 16:9
                </Text>
                <UploadButton>
                    <input type="file" id="upload" hidden onChange={uploadHandler} />
                    <UploadLabel htmlFor="upload">Upload New Image</UploadLabel>
                    <SaveButtonContainer style={saved ? {right: 0} : {}}>
                        <ButtonWrapper>
                            <SaveButton onClick={savePost}>
                                Save
                            </SaveButton>
                        </ButtonWrapper>
                    </SaveButtonContainer>
                </UploadButton>
            </div>
        </Container>
    )
}