import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f1f1f;
    border-bottom: 1px solid rgba(59,59,59,.8);
`
const UserContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
`
const Text = styled.span`
    font-size: 18px;
    color: white;
`
const Button = styled.button`
    padding: 10px 20px;
    border: none;
    outline: none;
    background-color: #1fb8cd;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 10px;
`

export default function Navbar() {

    const navigate = useNavigate();

    const handleLogout = () => {
        navigate("/");
    }

    return (
        <Container>
            <UserContainer>
                <Text></Text>
            </UserContainer>
            <Button onClick={handleLogout}>
                <Text>Logout</Text>
            </Button>
        </Container>
    )
}