import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { styled } from "styled-components";
import Post from "./item/Post";

const Container = styled.div`
    padding: 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 50px;
`

export default function Busstop() {
    const { tab } = useParams();
    const [selectedFile, setSelectedFile] = useState();
    const [images, setImages] = useState([]);
    const [changed, setChanged] = useState("");

    const changeHandler = (event, image) => {
        setSelectedFile(event.target?.files[0]);
        if(event.target?.files[0]) {
            setChanged(image._id);
        }
    };

    const changePost = (image) => {
        const directory = tab;
        const _id = image._id;
        const formData = new FormData();
        console.log("<<<<<<<<<", _id);

        formData.append('file', selectedFile);
        formData.append('directory', directory);
        formData.append('_id', _id);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/user/change`,
            data : formData
        };

        axios(config)
            .then(function (response) {
                setImages(response.data.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        setChanged("");
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/user/posts/busstop`)
            .then((res) => {
                console.log(res.data.data.data)
                setImages(res.data.data.data);
            });
    }, [])

    return (
        <Container>
            {images.map((image, index) => (
                <Post key={index} image={image} index={index} changeHandler={(event) => changeHandler(event, image)} changePost={() => changePost(image)} changed={changed} />
            ))}
        </Container>
    )
}