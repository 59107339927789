import React from "react";
import { useParams } from 'react-router-dom';
import { styled } from "styled-components";

const Container = styled.div`
    width: calc((100vw - 500px) / 3);
    height: calc((100vw - 500px) / 3 * 0.6);
    border: 2px solid #FFF;
    position: relative;
    overflow: hidden;
    &:hover {
        .button {
            display: ${props => props.changed !== "" ? "none" : "block"};
        }
        .image {
            filter: ${props => props.changed !== "" ? "" : "blur(5px)"};
        }
    }
`
const Text = styled.p`
    color: white;
    font-size: 16px;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
`
const ChangeButton = styled.div`
    padding: 7px 15px;
    background-color: #1fb8cd;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    user-select: none;
`
const ButtonGroup = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    display: flex;
`
const RotatingBillboardsChangeButton = styled.div`
    padding: 7px 15px;
    background-color: #1fb8cd;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    color: white;
    font-size: 16px;
    display: none;
    cursor: pointer;
    margin-right: 10px;
`
const RotatingBillboardsDeleteButton = styled.button`
    padding: 7px 15px;
    background-color: #1fb8cd;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    color: white;
    font-size: 16px;
    display: none;
    cursor: pointer;
`
const SaveButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-color: #111519;
    right: 101%;
    transition: right 0.5s ease-in-out;
`
const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SaveButton = styled.button`
    padding: 7px 15px;
    background-color: #1fb8cd;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
`

export default function Post({image, changeHandler, deletePost, changePost, changed}) {
    const { tab } = useParams();
    return (
        <div>
            <Text>
                File Format: JPG
                <br />
                Max Resolution Size: 2000 * 2000
                <br />
                Aspect Ratio:
            </Text>
            <Container changed={changed}>
                <Image className="image" src={image.image} alt="" />
                {tab === "rotatingbillboards" ? (
                        <ButtonGroup>
                            <RotatingBillboardsChangeButton className="button">
                                <input type="file" id={image._id} hidden onChange={changeHandler} />
                                <label htmlFor={image._id} style={{ cursor: "pointer" }}>Change Image</label>
                            </RotatingBillboardsChangeButton>
                            <RotatingBillboardsDeleteButton className="button" onClick={deletePost}>Delete</RotatingBillboardsDeleteButton>
                        </ButtonGroup>
                    ) : (
                        <ChangeButton className="button">
                            <input type="file" id={image._id} hidden onChange={changeHandler} />
                            <label htmlFor={image._id} style={{ cursor: "pointer" }}>Change Image</label>
                        </ChangeButton>
                    )
                }
                <SaveButtonContainer style={changed === image._id ? {right: 0} : {}}>
                    <ButtonWrapper>
                        <SaveButton onClick={changePost}>
                            Save
                        </SaveButton>
                    </ButtonWrapper>
                </SaveButtonContainer>
            </Container>
        </div>
    )
}