import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    border-sie: border-box;
    background-color: #1f1f1f;
`
const NavbarContainer = styled.div`
    width: 100%;
    height: 60px;
`
const MainContainer = styled.div`
    width: 100%;
    display: flex;
`
const SidebarContainer = styled.div`
    width: 300px;
    height: calc(100vh - 60px);
`
const ContentContainer = styled.div`
    width: calc(100% - 300px);
    height: calc(100vh - 60px);
    overflow-y: auto;
`

export default function MainLayout({children}) {
    return (
        <Container>
            <NavbarContainer>
                <Navbar />
            </NavbarContainer>
            <MainContainer>
                <SidebarContainer>
                    <Sidebar />
                </SidebarContainer>
                <ContentContainer>
                    {children}
                </ContentContainer>
            </MainContainer>
        </Container>
    )
}