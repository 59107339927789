import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const defaultTheme = createTheme();

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSigning, setIsSigning] = useState(false);
  
  const navigate = useNavigate();

  const handleSigninClick = () => {
    setIsSigning(true);
    const config = {
      method: 'post',
      url: `https://tcg.world/api/v1/auth/api-token/`,
      data : {
        email: email,
        password: password
      }
    };

    console.log(process.env.REACT_APP_LOCALSERVER_URL);

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setErrorMessage('');
      setIsSigning(false);
      navigate("/dashboard/busstop");
    })
    .catch(function (error) {
      console.log(error);
      setIsSigning(false);
      setErrorMessage(error.response.data.detail);
    });
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 2 }}>
              {errorMessage !== '' && <Typography component="h5" variant="subtitle2" sx={{textAlign: 'center', color: 'red'}}>
                {errorMessage}
              </Typography>}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />

              {!isSigning ? <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSigninClick}
              >
                Sign In
              </Button> : <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled
              >
                Signing...
              </Button>}

            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
