import React from "react";
import { useParams } from 'react-router-dom';
import MainLayout from "../layout/MainLayout";
import Busstop from "./Busstop";
import ConcertHall from "./ConcertHall";
import RotatingBillboards from "./RotatingBillboards";
import Stadium from "./Stadium";
import Bellyache from "./Bellyache";
import NewWebglVersion from "./NewWebglVersion";

export default function Dashboard() {
    const { tab } = useParams();

    return (
        <>
            <MainLayout>
                {tab === "busstop" && <Busstop />}
                {tab === "concerthall" && <ConcertHall />}
                {tab === "rotatingbillboards" && <RotatingBillboards />}
                {tab === "stadium" && <Stadium />}
                {tab === "bellyache" && <Bellyache />}
                {tab === "webglversion" && <NewWebglVersion />}
            </MainLayout>
        </>
    )
}